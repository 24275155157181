<template>
  <div class="xiaoxi-container">
    <navigator :userInfo="userInfo"></navigator>
    <div style="width: 1200px;margin: auto;padding-bottom: 50px">
      <div class="operate-div">
        <div></div>
        <div class="allRead-btn" @click="allRead">全部标记已读</div>
      </div>
      <div class="table-box">
        <div class="table-header table-cell">
          <div class="table-cell-row table-cell-row1">
            标题
          </div>
          <div class="table-cell-row table-cell-row2">
            科室
          </div>
          <div class="table-cell-row table-cell-row3">
            阅读量
          </div>
          <div class="table-cell-row table-cell-row4">
            时间
          </div>
          <div class="table-cell-row table-cell-row5">
            操作
          </div>
        </div>
        <div v-for="(item, index) in dataList" :key="index" class="table-cell">
          <div class="table-cell-row table-cell-row1" style="display: flex;align-items: center">
            <div v-if="item.wasRead == 0" class="unRead-icon"></div>
            <div v-else class="alRead-icon"></div>
            <div @click="toDetail(item)" style="width: 400px;text-overflow: ellipsis;overflow: hidden;white-space: nowrap">
              {{ item.messageTitle }}
            </div>
          </div>
          <div class="table-cell-row table-cell-row2">
            <span>{{ item.department }}  - {{ item.departmentPart }}</span>
          </div>
          <div class="table-cell-row table-cell-row3">
            {{ item.readingVolume }}
          </div>
          <div class="table-cell-row table-cell-row4">
            {{ item.createTime }}
          </div>
          <div class="table-cell-row table-cell-row5">
            <el-button @click="deleteDialog(item)" size="small" type="danger">删除</el-button>
          </div>
        </div>
      </div>
      <div style="width: 100%; text-align: center; padding-top: 15px">
        <el-pagination
                layout="prev, pager, next"
                @current-change="pageChange"
                :page-size="page.pageSize"
                :current-page="page.pageNum"
                :total="page.total">
        </el-pagination>
      </div>
    </div>
    <el-dialog
            title="提示"
            :visible.sync="dialogVisible"
            width="30%">
      <span>确认删除此网络发信？</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="confirmDelete">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
  import navigator from '../userCenter/navigator'
  import cookieUtil from "@/util/cookieUtil"
  export default {
    name: "index",
    components: {
      navigator
    },
    data() {
      return {
        dataList: [],
        pageSize: 20,
        pageNum: 1,
        dialogVisible: false,
        deleteObj: {},
        page: {
          pageSize: 20,
          pageNum: 1,
          total: 0
        },
        department: '',
        createTime: '',
        userInfo: {
          id: '',
          userName: '',
          trueName: '',
          schoolName: ''
        }
      }
    },
    mounted() {
      this.$api.home.getUserInfo().then(res => {
        if (res.code == 200) {
          Object.assign(this.userInfo, res.result)
          cookieUtil.setCookie({
            key: 'userInfo',
            value: JSON.stringify(res.result)
          })
          this.selectWebMessage()
        }
      })
    },
    methods: {
      allRead() {
        this.$api.home.update_read({
          userId: this.userInfo.id
        }).then(res => {
          this.selectWebMessage()
        })
      },
      departmentFilterChange(e) {
        this.department = e
        this.selectWebMessage()
      },
      pageChange(pageNum) {
        this.page.pageNum = pageNum
        this.selectWebMessage()
      },
      selectWebMessage() {
        let { department, createTime } = this
        let { pageSize, pageNum } = this.page
        this.$api.home.selectWebMessage({
          department,
          offset: (parseInt(pageNum) - 1) * pageSize,
          limit: pageSize,
          createTime,
          userId: this.userInfo.id
        }).then(res => {
          this.dataList = res.result.rows
        })
      },
      toEditNetMessage() {
        this.$router.push({
          name: 'netMessageDetail'
        })
      },
      deleteDialog(item) {
        this.dialogVisible = true
        this.deleteObj = item
      },
      confirmDelete() {
        this.$api.home.deleteWebMessage({
          id: this.deleteObj.id,
          userId: this.userInfo.id
        }).then(res => {
          if (res.code == 200) {
            this.$message({
              message: '删除成功',
              type: 'success'
            })
            this.dialogVisible = false
            this.selectWebMessage()
          }
        })
      },
      toDetail(item) {
        this.$router.push({
          name: 'netTransmitDetail',
          params: {
            id: item.id
          }
        })
      }
    }
  }
</script>

<style lang="scss" scoped>
  .xiaoxi-container {
    background-color: #fff;
    .allRead-btn {
      width: 107px;
      height: 36px;
      text-align: center;
      line-height: 36px;
      background-color: #F5F5F5;
      color: #4A4A4A;
      cursor: pointer;
    }
    .allRead-btn:hover {
      background-color: #EDEDED;
    }
    .operate-div {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 20px;
    }
    .table-box {
      width: 100%;
      min-height: 600px;
      padding: 0 20px;
      background-color: #fff;
    }
    .table-cell {
      display: flex;
    }
    .table-cell-row1 {
      flex: 1;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
    .table-cell-row2 {
      display: flex;
      justify-content: space-between;
      width: 180px;
    }
    .table-cell-row3 {
      width: 100px;
    }
    .table-cell-row4 {
      width: 190px;
      display: flex;
      justify-content: space-between;
    }
    .table-cell-row5 {
      width: 220px;
    }
    .table-cell-row {
      height: 65px;
      line-height: 65px;
      padding: 0 20px;
      border-bottom: 1px solid #eee;
      .unRead-icon {
        width: 10px;
        height: 10px;
        border-radius: 10px;
        margin-right: 10px;
        background-color: #EA5D5C;
      }
      .alRead-icon {
        width: 10px;
        height: 10px;
        border-radius: 10px;
        margin-right: 10px;
        background-color: #9B9B9B;
      }
    }
  }
</style>