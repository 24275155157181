<template>
  <div class="dongtaiDetail-container">
    <navigation></navigation>
    <div class="newsTitle">{{ newsTitle }}</div>
    <div class="createTime">{{ createTime }}</div>
    <div id="newsContent" class="newsContent">
    </div>
  </div>
</template>

<script>
  import navigation from '@/components/navigation'
  export default {
    name: "index",
    components: {
      navigation,
    },
    data() {
      return {
        newsTitle: '',
        createTime: '',
        newsContent: ''
      }
    },
    mounted() {
      this.readStudyNews()
    },
    methods: {
      readStudyNews() {
        this.$api.home.readStudyNews({
          id: this.$route.params.id
        }).then(res => {
          let { newsTitle, createTime, newsContent } = res.result
          this.newsTitle = newsTitle
          this.createTime = createTime
          document.getElementById('newsContent').innerHTML = newsContent
          this.newsContent = newsContent
        })
      }
    }
  }
</script>

<style lang="scss" scoped>
.dongtaiDetail-container {
  width: 1200px;
  margin: auto auto 30px;
  background-color: #fff;
  .newsTitle {
    width: 100%;
    padding: 30px 160px;
    font-size: 24px;
    font-weight: bolder;
  }
  .createTime {
    width: 100%;
    color: #4A4A4A;
    font-size: 14px;
    padding-bottom: 30px;
    border-bottom: 1px solid #ddd;
  }
  #newsContent > p {
    text-indent: 2em;
  }
}
@font-face {
  font-family: fangsong;
  src: url("../../assets/ttf/fangsong_GB2312.ttf");
}
.newsContent {
  padding: 20px 80px;
  min-height: 600px;
  font-family: fangsong;
}
</style>